import React, { ReactNode } from 'react';
import { PageProps, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Seo from '@/components/_layout/seo';
import { Button } from '@/components/_root';

interface Props {
  children: ReactNode;
}

const OverlayContainer = ({ children }: Props): JSX.Element => {
  return (
    <div className="bg-no-repeat w-full bg-center relative">
      <StaticImage
        src="../../assets/img/hero-overlay-1.png"
        alt=""
        className="w-full z-0 h-screen"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
      <div className="relative ">{children}</div>
    </div>
  );
};

const NotFoundPage = ({ location }: PageProps<any>): JSX.Element => {
  return (
    <OverlayContainer>
      <Seo title="404" />
      <div className="flex items-center justify-center h-screen text-white flex-col gap-7">
        <h1>404 - not found</h1>
        <p className="max-w-3xl text-center">
          The page you're looking for doesn't exist.
          <br /> You may have mistyped the address or the page has been moved.
        </p>
        <div className={'flex flex-row gap-4'}>
          <Button size="medium" href="/">
            Back to Home
          </Button>
          <Button size="medium" type="secondary" onClick={(): Promise<void> => navigate(-1)}>
            go back to previous page
          </Button>
        </div>
      </div>
    </OverlayContainer>
  );
};

export default NotFoundPage;
